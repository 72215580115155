import * as React from 'react';
import AceEditor from 'react-ace';
import { Badge, Button } from 'reactstrap';
import 'brace/theme/monokai';
import 'brace/mode/css';
import 'brace/ext/searchbox';
export function FormThemeCssTextEditor(props) {
    var doClose = props.doClose, doSaveStyleSheet = props.doSaveStyleSheet, errors = props.errors, isDirty = props.isDirty, isSaving = props.isSaving, requestValidation = props.requestValidation, _a = props.sheet, sheet = _a === void 0 ? '' : _a, validating = props.validating;
    var aceOptions = { showLineNumbers: true, tabSize: 2 };
    return (React.createElement(React.Fragment, null,
        React.createElement(AceEditor, { mode: 'css', theme: 'monokai', annotations: errors, onChange: requestValidation, fontSize: 18, hightlightActiveLine: true, showGutter: true, width: '100%', height: '700px', setOptions: aceOptions, value: sheet }),
        React.createElement("div", { style: {
                display: 'flex',
                padding: '2rem',
            } },
            doClose &&
                React.createElement(Button, { className: 'mr-auto', onClick: doClose }, "Close"),
            isDirty &&
                React.createElement(Badge, { className: 'theme-editor-badge', color: 'secondary' }, "Unsaved"),
            Boolean(errors.length) &&
                React.createElement(Badge, { className: 'theme-editor-badge', color: 'danger' }, "Errors"),
            validating &&
                React.createElement(Badge, { className: 'theme-editor-badge', color: 'warning' }, "Validating"),
            React.createElement(Button, { color: 'primary', disabled: validating || isSaving, onClick: doSaveStyleSheet }, isSaving ? 'Loading...' : 'Save'))));
}
