import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { batch } from 'react-redux';
import { alertAdd, alertError, alertSuccess, alertWarning } from '../alerts/alert_slice';
import { sliceThemeCssSheet } from './slices/slice_theme_css_sheet';
import { makeTheme } from '../ts_helpers/theme';
import { Merchi } from 'merchi_sdk_ts';
var middleware = getDefaultMiddleware({ serializableCheck: false });
var merchi = new Merchi();
export var store = configureStore({
    middleware: middleware,
    reducer: combineReducers({ stateThemeCssSheet: sliceThemeCssSheet.reducer })
});
export var alertThemeAdd = function (alertObject) { return alertAdd(store, alertObject); };
export var alertThemeError = function (m) { return alertError(store, m); };
export var alertThemeSuccess = function (m) { return alertSuccess(store, m); };
export var alertThemeWarning = function (m) { return alertWarning(store, m); };
export function initThemeEditToolbar(domainJson) {
    var activeTheme = domainJson.activeTheme;
    batch(function () {
        dispatch(sliceThemeCssSheet.actions.initStyleSheet(activeTheme));
    });
}
function dispatch(action) {
    store.dispatch(action);
}
export function toogleThemeStyleSheetEditorModal() {
    dispatch(sliceThemeCssSheet.actions.toggleIsOpen());
}
export function onStyleSheetEditorLoaded(text) {
    dispatch(sliceThemeCssSheet.actions.onStyleSheetEditorLoaded(text));
}
export function doValidateCssSheet(text) {
    var _a = sliceThemeCssSheet.actions, requestSheetValidation = _a.requestSheetValidation, requestSheetValidationError = _a.requestSheetValidationError, requestSheetValidationSuccess = _a.requestSheetValidationSuccess;
    dispatch(requestSheetValidation(text));
    var data = new FormData();
    data.set('text', text);
    merchi.authenticatedFetch('/themes/check/css/', { body: data, method: 'POST' }).
        then(function () {
        dispatch(requestSheetValidationSuccess());
    }).
        catch(function (resp) {
        dispatch(requestSheetValidationError(resp));
    });
}
export function doSaveStyleSheet() {
    var _a = sliceThemeCssSheet.actions, saveStyleSheet = _a.saveStyleSheet, saveStyleSheetError = _a.saveStyleSheetError, saveStyleSheetSuccess = _a.saveStyleSheetSuccess;
    var data = store.getState();
    var _b = data.stateThemeCssSheet, activeTheme = _b.activeTheme, sheet = _b.sheet;
    dispatch(saveStyleSheet());
    var theme = makeTheme(activeTheme);
    theme.mainCss = sheet;
    theme.mainCssTemplateEditing = sheet;
    theme.save({ embed: { mainCss: {}, mainCssTemplateEditing: {} } }).
        then(function (_theme) {
        var styleSheet = document.getElementById('merchi-theme-style-sheet');
        styleSheet.innerHTML = _theme.mainCss;
        batch(function () {
            alertThemeSuccess('Style sheet saved.');
            dispatch(saveStyleSheetSuccess());
        });
    }).catch(function (e) {
        batch(function () {
            alertThemeError(e.message);
            dispatch(saveStyleSheetError());
        });
    });
}
