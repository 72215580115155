var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
export var ModalAlert = function (props) {
    var alertClassName = props.alertClassName, alertSettings = props.alertSettings, showAlert = props.showAlert;
    var alertType = alertSettings ? (alertSettings.type || alertSettings.alertType) : 0;
    var show = alertSettings ? alertSettings.show : false;
    var title = {
        danger: 'Error!',
        error: 'Error!',
        info: 'Info!',
        success: 'Success!',
        warning: 'Warning!',
    };
    var alertClass = {
        danger: 'danger ',
        error: 'danger ',
        info: 'info ',
        success: 'success ',
        warning: 'warning ',
    };
    return (React.createElement(Alert, { className: alertClassName ? alertClassName : '', color: alertClass[alertType], isOpen: show, toggle: function () {
            alertSettings.show = false;
            if (showAlert) {
                showAlert(alertSettings);
            }
        } },
        React.createElement("strong", null, title[alertType]),
        " ",
        alertSettings ? alertSettings.message : ''));
};
export var inModal = function (Component) {
    return /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            return _super.call(this, props) || this;
        }
        class_1.prototype.render = function () {
            var _a = this.props, alertClassName = _a.alertClassName, alertSettings = _a.alertSettings, actionBtnClass = _a.actionBtnClass, _b = _a.actionBtnColor, actionBtnColor = _b === void 0 ? 'primary' : _b, actionName = _a.actionName, _c = _a.backdrop, backdrop = _c === void 0 ? true : _c, centered = _a.centered, _d = _a.closeText, closeText = _d === void 0 ? 'Cancel' : _d, containerClass = _a.containerClass, downloadHref = _a.downloadHref, formDisabled = _a.formDisabled, formId = _a.formId, hasAlert = _a.hasAlert, hideFooter = _a.hideFooter, hideModalHead = _a.hideModalHead, isLoading = _a.isLoading, isOpen = _a.isOpen, modalClass = _a.modalClass, onWantAction = _a.onWantAction, onWantClose = _a.onWantClose, showAlert = _a.showAlert, title = _a.title, _e = _a.size, size = _e === void 0 ? 'lg' : _e, style = _a.style, internalProps = __rest(_a, ["alertClassName", "alertSettings", "actionBtnClass", "actionBtnColor", "actionName", "backdrop", "centered", "closeText", "containerClass", "downloadHref", "formDisabled", "formId", "hasAlert", "hideFooter", "hideModalHead", "isLoading", "isOpen", "modalClass", "onWantAction", "onWantClose", "showAlert", "title", "size", "style"]);
            return (React.createElement(Modal, { backdrop: backdrop, centered: centered, className: containerClass, isOpen: isOpen, size: size, style: style, toggle: onWantClose },
                !hideModalHead &&
                    React.createElement(ModalHeader, { toggle: onWantClose, className: 'text-capitalize' }, title),
                React.createElement(ModalBody, { className: modalClass },
                    hasAlert &&
                        React.createElement(ModalAlert, { alertClassName: alertClassName, alertSettings: alertSettings, showAlert: showAlert }),
                    React.createElement(Component, __assign({}, internalProps, { formId: formId }))),
                !hideFooter &&
                    React.createElement(ModalFooter, null,
                        React.createElement(Button, { className: 'mr-auto', color: 'secondary', onClick: onWantClose }, closeText),
                        downloadHref &&
                            React.createElement(Button, { href: downloadHref, color: actionBtnColor, className: actionBtnClass, disabled: isLoading, download: true },
                                React.createElement(FontAwesomeIcon, { icon: faDownload }),
                                ' ',
                                React.createElement("span", { className: 'd-inline' }, "Download")),
                        !formDisabled &&
                            React.createElement(React.Fragment, null, Boolean(onWantAction || formId) &&
                                React.createElement(Button, { color: actionBtnColor, className: actionBtnClass, disabled: isLoading, onClick: onWantAction, form: formId }, isLoading ? 'Loading...' : actionName)))));
        };
        return class_1;
    }(React.Component));
};
