import * as React from 'react';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { FormThemeCssTextEditor } from '../forms/FormThemeCssTextEditor';
import { doSaveStyleSheet, doValidateCssSheet, onStyleSheetEditorLoaded, toogleThemeStyleSheetEditorModal, } from '../store';
import { inModal } from '../../modal';
var doValidate = debounce(doValidateCssSheet, 1000);
function Content() {
    var state = useSelector(function (s) { return s.stateThemeCssSheet; });
    var errors = state.errors, isDirty = state.isDirty, isUnsaved = state.isUnsaved, saving = state.saving, sheet = state.sheet, validating = state.validating;
    return (React.createElement(FormThemeCssTextEditor, { doClose: toogleThemeStyleSheetEditorModal, doSaveStyleSheet: doSaveStyleSheet, errors: errors, isDirty: isDirty, isSaving: saving, isUnsaved: isUnsaved, onStyleSheetEditorLoaded: onStyleSheetEditorLoaded, requestValidation: doValidate, sheet: sheet, validating: validating }));
}
export var ModalEditThemeCss = inModal(Content);
