var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { errorAlertObj, infoAlertObj, successAlertObj, warningAlertObj, } from '../ts_helpers/alert';
export function alertContent(message, title, errotObj) {
    return { errorObj: errotObj && errotObj.errorMessage ? errotObj.errorMessage : errotObj,
        message: message,
        title: title };
}
export var alertSlice = createSlice({
    initialState: {
        alerts: [],
    },
    name: 'alertState',
    reducers: {
        alertAdd: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [action.payload], false);
        },
        alertError: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [{ alertType: 'danger',
                    message: action.payload,
                    title: 'Error!' }], false);
        },
        alertErrorWithTitle: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [{ alertType: 'danger',
                    message: action.payload.message,
                    title: action.payload.title }], false);
        },
        alertInfo: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [{ alertType: 'info',
                    message: action.payload,
                    title: 'Info!' }], false);
        },
        alertRemove: function (state, action) {
            state.alerts.splice(action.payload, 1);
        },
        alertSuccess: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [{ alertType: 'success',
                    message: action.payload,
                    title: 'Success!' }], false);
        },
        alertWarning: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [{ alertType: 'warning',
                    message: action.payload,
                    title: 'Warning!' }], false);
        },
        customAlert: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [action.payload], false);
        },
        errorAlert: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [errorAlertObj(action.payload)], false);
        },
        infoAlert: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [infoAlertObj(action.payload)], false);
        },
        removeAlert: function (state, action) {
            state.alerts.splice(action.payload, 1);
        },
        successAlert: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [successAlertObj(action.payload)], false);
        },
        warningAlert: function (state, action) {
            state.alerts = __spreadArray(__spreadArray([], __read(state.alerts), false), [warningAlertObj(action.payload)], false);
        },
    },
});
export function alertAdd(store, alertObject) {
    store.dispatch(alertSlice.actions.alertAdd(alertObject));
}
export function alertRemove(store, index) {
    store.dispatch(alertSlice.actions.alertRemove(index));
}
export function alertError(store, message) {
    store.dispatch(alertSlice.actions.alertError(message));
}
export function alertErrorWithTitle(store, message, title) {
    store.dispatch(alertSlice.actions.alertErrorWithTitle({ message: message, title: title }));
}
export function alertSuccess(store, message) {
    store.dispatch(alertSlice.actions.alertSuccess(message));
}
export function alertWarning(store, message) {
    store.dispatch(alertSlice.actions.alertWarning(message));
}
