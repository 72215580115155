var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { urlFor } from '../../public_components/url_map';
import { constants, Merchi } from 'merchi_sdk_ts';
var ThemeFoundations = constants.theme_foundations.ThemeFoundations;
var merchi = new Merchi();
var defaultFeature = urlFor('static', { filename: 'images/merchi-monster-blue.png' });
export function themeFeatureImage(theme) {
    return theme && theme.featureImage ? theme.featureImage.viewUrl : defaultFeature;
}
export function makeTheme(json, makeDirty) {
    var theme = new merchi.Theme();
    if (makeDirty) {
        return theme.fromJson(json, { makeDirty: true });
    }
    else {
        return theme.fromJson(json);
    }
}
export function makeThemePage(json, makeDirty) {
    var page = new merchi.Page();
    return page.fromJson(json, { makeDirty: makeDirty });
}
export var themeFoundations = [
    { name: 'No foundation', value: ThemeFoundations.NO_FOUNDATION },
    { name: 'Bootstrap 3', value: ThemeFoundations.BOOTSTRAP_3 },
    { name: 'Bootstrap 4', value: ThemeFoundations.BOOTSTRAP_4 },
    { name: 'Bootstrap 5', value: ThemeFoundations.BOOTSTRAP_5 },
];
export function themeSanitiseForCreation(themeJson) {
    var e_1, _a;
    var themeTemplateAttrs = [
        "companyProfilePageError",
        "defaultForDomainType",
        "domainInvitePageError",
        "domainInvitePageTemplate",
        "draftPreviewPageError",
        "draftPreviewPageTemplate",
        "emailCss",
        "emailCssErrorMessage",
        "emailCssStatus",
        "emailCssTemplateEditing",
        "emailCssTemplateUsing",
        "errorPageError",
        "errorPageTemplate",
        "footerError",
        "footerTemplate",
        "headerError",
        "headerTemplate",
        "indexPageError",
        "indexPageTemplate",
        "invoicePageError",
        "invoicePageTemplate",
        "invoicePaidPageError",
        "invoicePaidPageTemplate",
        "jobDraftingPageError",
        "jobDraftingPageTemplate",
        "jobQuoteRequestedPageError",
        "jobQuoteRequestedPageTemplate",
        "loginPageError",
        "loginPageTemplate",
        "mainCss",
        "mainCssErrorMessage",
        "mainCssTemplateEditing",
        "mainCssTemplateUsing",
        "passwordChangePageError",
        "passwordChangePageTemplate",
        "productPageError",
        "productPageTemplate",
        "resetPasswordPageError",
        "resetPasswordPageTemplate",
        "userProfilePageError",
        "userProfilePageTemplate",
    ];
    try {
        for (var _b = __values(Object.keys(themeJson)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var key = _c.value;
            var value = themeJson[key];
            if (!value && themeTemplateAttrs.includes(key)) {
                themeJson[key] = '';
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return __assign({}, themeJson);
}
