import * as React from 'react';
import { Provider, useSelector } from 'react-redux';
import { ModalEditThemeCss } from '../profile_theme/modals/ModalEditThemeCss';
import { store, toogleThemeStyleSheetEditorModal, } from '../profile_theme/store';
import { urlFor } from '../../public_components/url_map';
import { Tooltip } from '../components/tooltips/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileCode } from '@fortawesome/free-solid-svg-icons';
function ModalStyleSheet() {
    var state = useSelector(function (s) { return s.stateThemeCssSheet; });
    var isOpen = state.isOpen;
    return (React.createElement(ModalEditThemeCss, { centered: true, hideFooter: true, isOpen: isOpen, onWantClose: toogleThemeStyleSheetEditorModal, size: 'xl', title: 'Edit Theme Style Sheet' }));
}
export function EditThemeToolbar(props) {
    var domain = window.currentDomain;
    var user = window.currentUser;
    var identifier = domain ? domain.activeThemeId() : null;
    var canEdit = user && user.id > 0 && domain || user && user.isSuperUser() ?
        user.canEditTheme(domain.id()) : false;
    return (React.createElement(Provider, { store: store }, canEdit &&
        React.createElement(React.Fragment, null,
            React.createElement(ModalStyleSheet, null),
            React.createElement("style", null, "\n              .edit-merchi-theme-toolbar {\n                background: #303dbf;\n                display: flex;\n                position: fixed;\n                bottom: 10px;\n                right: 10px;\n                text-decoration: none;\n                -webkit-border-radius: 0.5rem;\n                -moz-border-radius: 0.5rem;\n                border-radius: 0.5rem;\n              }\n              .edit-merchi-theme-button {\n                color: #fff;\n                cursor: pointer;\n                display: inline-block;\n                padding: 1.5rem;\n                text-decoration: none;\n                -webkit-transition: all 0.3s linear;\n                -moz-transition: all 0.3s ease;\n                -ms-transition: all 0.3s ease;\n                -o-transition: all 0.3s ease;\n                transition: all 0.3s ease;\n              }\n              .edit-merchi-theme-button svg {\n                color: #fff;\n                font-size: 19px;\n                -webkit-transition: all 0.3s ease;\n                -moz-transition: all 0.3s ease;\n                -ms-transition: all 0.3s ease;\n                -o-transition: all 0.3s ease;\n                transition: all 0.3s ease;\n              }\n              .edit-merchi-theme-button:hover {\n                background-color: #303dbf;\n                border-color: #303dbf;\n              }\n              .edit-merchi-theme-button:hover svg {\n                color: #fff;\n              }\n              .theme-editor-badge {\n                display: inline-block;\n                padding: 0.25em 0.4em;\n                font-size: 75%;\n                font-weight: 700;\n                line-height: 1;\n                text-align: center;\n                white-space: nowrap;\n                vertical-align: baseline;\n                border-radius: 0.25rem;\n              }\n            "),
            identifier &&
                React.createElement("div", { className: 'edit-merchi-theme-toolbar' },
                    React.createElement(Tooltip, { tooltip: 'Edit theme style sheet' },
                        React.createElement("a", { onClick: toogleThemeStyleSheetEditorModal, className: 'edit-merchi-theme-button' },
                            React.createElement(FontAwesomeIcon, { icon: faFileCode }))),
                    React.createElement(Tooltip, { tooltip: 'Edit theme profile' },
                        React.createElement("a", { href: urlFor('theme_edit', { identifier: identifier }), className: 'edit-merchi-theme-button', target: 'blank' },
                            React.createElement(FontAwesomeIcon, { icon: faEdit })))))));
}
