import { createSlice } from '@reduxjs/toolkit';
function makeErrorObject(err) {
    return {
        column: err.column,
        row: Math.max(0, err.line - 1),
        text: err.message,
        type: 'error',
    };
}
export var sliceThemeCssSheet = createSlice({
    initialState: {
        activeTheme: {},
        editor: null,
        errors: [],
        isDirty: false,
        isOpen: false,
        isUnsaved: false,
        saving: false,
        sheet: undefined,
        validating: false,
    },
    name: 'stateThemeCssSheet',
    reducers: {
        saveStyleSheet: function (state) {
            state.saving = true;
        },
        saveStyleSheetError: function (state) {
            state.saving = false;
        },
        saveStyleSheetSuccess: function (state) {
            state.saving = false;
            state.isDirty = false;
            state.isUnsaved = false;
        },
        initStyleSheet: function (state, action) {
            state.activeTheme = action.payload;
            state.sheet = action.payload.mainCss;
        },
        onStyleSheetEditorLoaded: function (state, action) {
            state.editor = action.payload;
        },
        requestSheetValidation: function (state, action) {
            state.sheet = action.payload;
            state.validating = true;
            state.isDirty = true;
            state.isUnsaved = true;
        },
        requestSheetValidationError: function (state, action) {
            var serverError = action.payload;
            state.validating = false;
            if (serverError.original && serverError.original.errors) {
                state.errors = serverError.original.errors.map(makeErrorObject);
            }
        },
        requestSheetValidationSuccess: function (state) {
            state.validating = false;
            state.errors = [];
        },
        toggleIsOpen: function (state) {
            state.isOpen = !state.isOpen;
        },
    },
});
